/*
 * File: Unsuscribe.tsx
 * Project: autobuyclient
 * File Created: Wednesday, 17th January 2024 03:14:00
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 17th January 2024 06:16:31
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */
import RouterPathResolver from "Config/Router/RouterPathResolver";
import Styles from "Content/Unsuscribe/Unsuscribe.module.css";
import { useLang } from "Router/LangueProvider";
import { useNavigate } from "react-router-dom";
import { useLoaderData } from "react-router-dom";

function Unsuscribe() {
    const loadData = useLoaderData() as {success: boolean};
    const navigate = useNavigate();
    const {translator} = useLang();

    return (
        <>
            <div className={`${Styles.global}`}>
                <img loading="lazy" className={`${Styles.backgroundImg}`} src="/assets/parking.png" alt={translator.decode("image.background")} />
                <div className={`${Styles.formHolder}`} >
                    {
                        loadData.success ?
                        <p>{translator.decode("user.unsuscribe.success")}</p>
                        :
                        <p>{translator.decode("user.unsuscribe.failure")}</p>
                    }
                    <button className="btn btn-primary" onClick={() => navigate(RouterPathResolver.pathHome.path, {replace: true})}>{translator.decode("modal.backHome")}</button>
                </div>
            </div>
        </>
        )
}

export default Unsuscribe;
