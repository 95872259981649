/*
 * File: CarComment.tsx
 * Project: autobuyclient
 * File Created: Friday, 12th January 2024 07:32:46
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 12th January 2024 08:19:45
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useLang } from "Router/LangueProvider";

function CarComments(props: {comments: string, cost: number | null | undefined}) {
    const {translator} = useLang();

    return (
        <>
            <h2>{translator.decode("car.comments").toUpperCase()}</h2>
            {
                ![0, null, undefined].includes(props.cost) ?
                <p>{translator.decode("car.cost.cost")} {props.cost} €</p>
                : null
            }
            {
                !["", null, undefined].includes(props.comments) ?
                <p>{props.comments} </p>
                : <i>{translator.decode("car.no_comments")}</i>
            }
        </>
    )
}

export default CarComments;
