/*
 * File: AccountValidated.tsx
 * Project: autobuyclient
 * File Created: Friday, 29th September 2023 02:42:33
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 2nd October 2023 01:03:26
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import PasswordConfirm from "Content/Password/PasswordConfirm/PasswordConfirm";
import { useLang } from "Router/LangueProvider";
import { Fragment } from "react";
import { useParams } from "react-router-dom";

const AccountValidated = () => {
    const params = useParams();
    const {translator} = useLang();

    return (<Fragment>
                <PasswordConfirm token={params.token ?? ""} renewal={true} >
                    <h1>{translator.decode('user.welcome')}</h1>
                </PasswordConfirm>
            </Fragment>)
}

export default AccountValidated;
