/*
 * File: VerificationService.ts
 * Project: autobuyclient
 * File Created: Tuesday, 19th September 2023 09:10:00
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 19th September 2023 10:36:36
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import axios from "axios";
import Config from "Config/Config";

class VerificationService {
    public async verifyToken(token: string) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.VERIFY_TOKEN}`;
        try {
            const state = await axios.post(url, {token}, {headers: {"Content-Type": "application/json"}});
            return state.data;
        } catch (reason: any) {
            console.error(`Unable to pull data: ${reason.message}`)
            return false
        }
    }
}

export default new VerificationService()
