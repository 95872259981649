/*
 * File: AdressService.ts
 * Project: autofi-front
 * File Created: Thursday, 2nd March 2023 05:12:55
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 19th September 2023 12:10:05
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */
import { buildQueryString } from "build-url-ts";
import Config from "Config/Config";
import { OptionType } from "Content/Contact/ContactSelect/OptionTypeInterface";

type QueryParams = { [key: string]: string | string[] };

// type QueryParams = { [key: string]: string | string[] };

export const getAddresses = async (input: string) => {
  const addr: QueryParams = { limit: "10" };

  addr["text"] = input;
  addr["format"] = "json";
  addr["apiKey"] = Config.COMMON.MAP_API.KEY;
  const query = buildQueryString(addr);
  const uri = Config.COMMON.MAP_API.ADDR + query;
  const url = uri;
  const tmp = await fetch(url);
  const result = await tmp.json();
  const propositions: OptionType[] = [];
  if (result["results"] !== undefined) {
    for (const res of result["results"]) {
      if (res !== undefined)
        propositions.push({
          label: res["formatted"],
          value: res["formatted"],
          data: {
            houseNumber: res["housenumber"],
            street: res["street"],
            postalCode: res["postcode"],
            city: res["city"],
            state: res["state"],
            country: res["country"],
            score: res["score"],
          },
        });
    }
  }
  return propositions;
};
// export const getAddresses = async (input: string) => {
//   const addr: QueryParams = { limit: "15" };

//   addr["q"] = input.replaceAll(" ", "+");
//   const query = buildQueryString(addr);
//   const url = "https://api-adresse.data.gouv.fr/search" + query;
//   const tmp = await fetch(url);
//   const result = await tmp.json();
//   const propositions: OptionType[] = [];
//   if (result["features"] !== undefined) {
//     for (const res in result["features"]) {
//       const feat = result["features"][res];
//       const prop = feat["properties"];
//       if (feat !== undefined && prop !== undefined)
//         propositions.push({
//           label: prop["label"],
//           value: prop["label"],
//           data: {
//             houseNumber: prop["housenumber"],
//             street: prop["street"],
//             postalCode: prop["postcode"],
//             city: prop["city"],
//             score: prop["score"],
//             country: prop["country"]
//           },
//         });
//     }
//   }
//   return propositions;
// };
