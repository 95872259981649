/*
 * File: ValidationSuccess.tsx
 * Project: autobuyclient
 * File Created: Monday, 18th September 2023 10:55:03
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 19th September 2023 11:26:46
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useLang } from "Router/LangueProvider";
import { Fragment, useEffect, useState } from "react";
import Styles from "Content/CreateAccount/ValidationSuccess.module.css";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import { useLoaderData, useNavigate } from "react-router-dom";
import RouterPathResolver from "Config/Router/RouterPathResolver";

const ValidationSuccess = () => {
    const navigate = useNavigate();
    const {translator} = useLang();
    const [spinner, setSpinner] = useState(true);
    const success = useLoaderData();

    useEffect(() => {
        if (success !== undefined)
            setSpinner(false);
    }, [success]);

    return (
        <div className={`${Styles.createAccountPage}`}>
            {
                spinner ?
                    <div className={`${Styles.spinnerHolder}`}>
                        <LoadingScreen />
                    </div>
                    :
                    <Fragment>
                        <img loading="lazy" className={`${Styles.backgroundImg}`} src="/assets/parking.png" alt={translator.decode("image.background")} />
                        <div className={`${Styles.formHolder}`} >
                            {
                                success ?
                                <p>{translator.decode("user.create.validation_success")}</p>
                                :
                                <p>{translator.decode("user.create.validation_error")}</p>
                            }
                            <button className="btn btn-primary" onClick={() => navigate(RouterPathResolver.pathHome.path, {replace: true})}>{translator.decode("modal.backHome")}</button>
                            {/* {
                                success ?
                                <a href="">{translator.decode("user.cgv_dl")}</a>
                                : null
                            } */}
                        </div>
                    </Fragment>
            }
        </div>
    )
}

export default ValidationSuccess;
